import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "plus", "minus", "quantity", "submit", "submitText", "submitSpinner" ]
  static values = {
    quantity: { type: Number, default: 1 },
    min: { type: Number, default: 1 },
    max: { type: Number, default: 20 },
    price: { type: Number, default: 0 },
    newRecord: { type: Boolean, default: true }
   }

  connect() {
    console.log("item show connect to ", {
      q: this.quantityValue, min: this.minValue, max: this.maxValue, newRecord: this.newRecordValue, price: this.priceValue
    })
    // this.calculateTotal();
    // this.plusTarget.disabled = true;
    this.minusTarget.disabled = this.quantityValue == 1;
    this.submitTextTarget.textContent = "Loading";

    // TODO: disable any primary required options
    // find the primary group and each option
    var outerSelf = this;
    $("input", ".option-group[data-primary=true]").each( function() {
      if(this.checked) {

      } else {
        outerSelf.disableOptions($(this).data("name"));
      }
    })

    this.calculateTotal();

    var klass = this;
    $('input', this.element).off("change").on("change", function() {

      // console.log("item.js checked", this);

      var option_group = $(this).closest('.option-group');
      var primary = option_group.data("primary");

      var self = this;
      var checked = $('input', option_group).filter( function() { return this.checked && this !== self; });
      var count = checked.length;


      if (option_group.data('max')) {
        var max = option_group.data('max');
        // console.log("max", max);
        if(count >= max) {
          checked[0].checked = false;

          if(primary) {
            // $(".option input[data-name$=\"("+ $(checked[0]).data("name") +")\"] ").prop('disabled', true);
            outerSelf.disableOptions($(checked[0]).data("name"));
          }
        }
      }

      if (primary)
      {
        // console.log("primary");
        // TODO: find and disable/enabled mods with
        // console.log("enable", $(".option input[data-name$=\"("+ $(this).data("name") +")\"] "));
        // console.log("disable", $(".option input[data-name~="+ this.data("name") +"]"));

        // $(".option input[data-name$=\"("+ $(this).data("name") +")\"] ").prop('disabled', false);
        outerSelf.enableOptions($(this).data("name"));
      }

      var option;
      if ($(this).is(':radio')) {
        $('.option', option_group).removeClass('checked');
        option = $(this).closest('.option');
        $(option).addClass('checked');
      } else {
        option = $(this).closest('.option');
        $(option).toggleClass('checked');
      }

      klass.calculateTotal();

      return true;
    });

    // console.log("item.js select ", $('select', this.element).length);
    $('select', this.element).off("change").on("change", function() {

      // console.log("item.js change", this);

      var option_group = $(this).closest('.option-group');

      var checked = $('select', option_group).map( function() { return +this.value; }).toArray();
      var count = checked.reduce(function(previousValue, currentValue) {
        return previousValue + currentValue;
      }, 0);

      if (option_group.data('max')) {
        var max = option_group.data('max');
        console.log("max", max);
        if(count >= max) {
          // todo something
          // console.log("could prevent defualt here");
          // checked[0].checked = false;
        }
      }

      klass.calculateTotal();

      return true;
    });

    $(this.element).on('ajax:error', function(event, xhr, status, error) {
      // insert the failure message inside the "#account_settings" element
      appropo.log("ajax:error", error);
      klass.submitTarget.disabled = false;
      klass.submitTextTarget.textContent = error;
      klass.submitSpinnerTarget.style = "display: none";
      klass.submitTarget.classList.remove("btn-success");
      klass.submitTarget.classList.add("btn-danger");

      setTimeout(function() {
        klass.submitTarget.classList.add("btn-success");
        klass.submitTarget.classList.remove("btn-danger");
        klass.calculateTotal();
      }, 2500);
    });
  }

  disableOptions(name) {
    $(`.option input[data-name$="(${name})"] `)
      .prop('disabled', true);

    $(`.option input[data-name$="(${name})"] `)
      .closest(".option")
      .hide();
  }

  enableOptions(name) {
    $(`.option input[data-name$="(${name})"] `)
      .prop('disabled', false);

    $(`.option input[data-name$="(${name})"] `)
      .closest(".option")
      .show();
  }

  disconnect() {
    $(this.element).off('ajax:error');
    $('select', this.element).off("change");
    $('input', this.element).off("change");
  }

  initialize() {
    this.formatter = new Intl.NumberFormat('en-NZ', {
      style: 'currency',
      currency: 'NZD',
      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
  }

  submit(event) {
    appropo.log("form submit", event);
    this.submitTarget.disabled = true;
    this.submitTextTarget.textContent = "";
    this.submitSpinnerTarget.style = "";
    // change button to loading
  }

  // submitError(event) {
  //   appropo.log("ajax:error", event);
  //   let [data, status, xhr] = event.detail;

  //   this.submitTarget.disabled = false;
  //   this.submitTextTarget.textContent = xhr.response;
  //   this.submitSpinnerTarget.style = "display: none";
  // }

  increment(event) {
    event.preventDefault();
    this.quantityValue += 1;
    if(this.quantityValue >= this.maxValue) {
      this.quantityValue = this.maxValue;
      this.plusTarget.disabled = true;
    }
    this.minusTarget.disabled = false;
    // console.log("increment", this.quantityValue);
    this.calculateTotal();
  }

  decrement(event) {
    event.preventDefault();
    this.quantityValue -= 1;
    if(this.quantityValue <= this.minValue) {
      this.quantityValue = this.minValue;
      this.minusTarget.disabled = true;
    }
    this.plusTarget.disabled = false;
    // console.log("decrement", this.quantityValue);
    this.calculateTotal();
  }

  calculateTotal() {
    var quantity = this.quantityValue;

    this.quantityTarget.value = quantity;

    var price = this.priceValue;

    // TODO: remove the jquery and make it more stimulus
    var addons = $('input', '.option-group')
      .filter( function() { return this.checked && !this.disabled; })
      .map( function() { return $(this).data('value'); })
      .toArray()
      .reduce( function(total, number) { return total + (+number); }, 0);

    var extras = $('select', '.option-group')
      .map( function() { return +this.value * +$(this).data('value'); } )
      .filter( function() { return !this.disabled; })
      .toArray()
      .reduce( function(total, number) { return total + (+number); }, 0);

      var unit_price = price + addons + extras;
      var total = quantity * unit_price;

    // check for outstanding required props from validate
    var valid = this.validate();

    if(valid)
    {
      if(total === 0) {
        total = "FREE";
      } else {
        total = this.formatter.format(total);
      }

      var addOrUpdate = this.newRecordValue ? "Add" : "Update";

      this.element.disabled = false;
      this.submitTarget.disabled = false;
      this.submitTextTarget.textContent = `${addOrUpdate} ${quantity} - ${total}`;
    }
    else
    {
      this.element.disabled = true;
      this.submitTarget.disabled = true;
      this.submitTextTarget.textContent = "Select Required Options";
    }
  }

  // sum() {
  //   var quantity = this.quantityValue;
  //   var price = this.priceValue;

  //   var addons = $('input', '.option-group')
  //     .filter( function() { return this.checked; })
  //     .map( function() { return $(this).data('value'); })
  //     .toArray()
  //     .reduce( function(total, number) { return total + (+number); }, 0);

  //   var extras = $('select', '.option-group')
  //     .map( function() { return +this.value * +$(this).data('value'); } )
  //     .toArray()
  //     .reduce( function(total, number) { return total + (+number); }, 0);

  //   var unit_price = price + addons + extras;
  //   var total = quantity * unit_price;
  //   $('#unit_price').val(unit_price);
  //   $('.unit_price').html(numeral(unit_price).format('$0,0.00'));
  //   $(".item_quantity").html(quantity);
  //   $("#item_total").html(numeral(total).format('$0,0.00'));
  //   validate();
  // }

  validate() {
    var valid = true;

    $("input[type=submit]", ".order_form").disabled = !valid;

    $('.option-group').each( function() {
      var valid_group = true;
      var option_group = $(this);

      var self = this;
      var checked = $('input', option_group).filter( function() { return this.checked && this !== self; });
      var checked_count = checked.length;

      var selected = $('select', option_group).map( function() { return +this.value; }).toArray();
      var selected_count = selected.reduce(function(previousValue, currentValue) {
        return previousValue + currentValue;
      }, 0);
      var count = checked_count + selected_count;

      var min = option_group.data("min");
      if(min && count < min) {
        valid = false;
        valid_group = false;
      }

      var max = option_group.data("max");
      if(max && count > max) {
        valid = false;
        valid_group = false;
      }

      if (valid_group) {
         //console.log('valid', option_group);
        option_group.removeClass('invalid');
      } else {
         //console.log('invalid', option_group);
        option_group.addClass('invalid');
      }

    });

    // console.log("valid: ", valid);
    return valid;
  }
}
